const getAPIConfig = () => {
  const authToken = localStorage.getItem("token");
  const config = {
    headers: {
      Authorization: authToken,
      "Content-Type": "application/json",
    },
  };
  return config;
};

const getIcon = (iconText) => {
  return (
    {
      video: "bi-camera-video-fill",
      image: "bi-card-image",
      pdf: "bi-filetype-pdf",
      audio: "bi-volume-up-fill",
      document: "bi-file-earmark-text",
      webpage: "bi-filetype-html",
      link: "bi-link-45deg",
      info: "bi-info-circle-fill",
    }[iconText] || "bi-file-text-fill"
  );
};

export { getAPIConfig, getIcon };
