export const excludedPerformanceKeys = {
    tradedesk: ['Campaign ID', 'Ad Group ID', 'Creative ID'],
    facebook: ['account_id', 'ad_id', 'adset_id', 'campaign_id'],
    instagram: ['account_id', 'ad_id', 'adset_id', 'campaign_id']
  };
  
export const excludedPlatformSpecificKeys = {
    facebook:['external_url'],
    instagram:['external_url'],
    linkedin:['content_landing_page'],
    google_ads:['display_url', 'final_urls', 'youtube_url'],
    Offline_Media: ['audio', 'color_range', 'common_name', 'duration', 'encoding', 'file_owner', 'filepath'],
  };

export const topPerformanceKeys = {
  google_ads: ['ctr', 'active_view_ctr', 'average_cpc', 'engagements', 'engagement_rate', 'active_view_cpm', 'average_cpm', 'average_cpe', 'average_cpv', 'clicks', 'impressions', 'conversions', 'interactions', 'interaction_rate' ]
};