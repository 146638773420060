// src/hooks/useCustomDispatch.js
import { useDispatch } from "react-redux";
import {
  setSelectedCategory,
  setSelectedPlatform,
  setSelectedType,
  setSelectedStatus,
  setSelectedPerformance,
} from "../../../redux/actions";

const useCustomDispatch = () => {
  const dispatch = useDispatch();

  const handleCategoryChange = (category) => {
    if (Array.isArray(category)) {
      category.forEach((item) => {
        dispatch(setSelectedCategory(item, false));
      });
    } else {
      dispatch(setSelectedCategory(category, true));
    }
  };

  const handlePlatformsChange = (platform) => {
    if (Array.isArray(platform)) {
      platform.forEach((item) => {
        let updatedPlatform =
          item === "facebook"
            ? "Facebook"
            : item === "instagram"
            ? "Instagram"
            : item === "linkedin"
            ? "Linkedin"
            : item === "reddit"
            ? "Reddit"
            : item === "google_ads"
            ? "Google_Ads"
            : item === "google_analytics"
            ? "Google_Analytics"
            : item === "offline_media"
            ? "Offline_Media"
            : item === "tradedesk"
            ? "TradeDesk"
            : item;
            
        dispatch(setSelectedPlatform(updatedPlatform, false));
      });
    } else {
      dispatch(setSelectedPlatform(platform, true));
    }
  };

  const handleTypesChange = (type) => {
    if (Array.isArray(type)) {
      type.forEach((item) => {
        let updatedType =
          item === "image"
            ? "Image"
            : item === "video"
            ? "Video"
            : item === "audio"
            ? "Audio"
            : item === "blog"
            ? "Blog"
            : item === "pdf"
            ? "PDF"
            : item === "document"
            ? "Document"
            : item === "webpage"
            ? "Webpage"
            : item === "link"
            ? "Link"
            : item === "text"
            ? "Text"
            : item;
        dispatch(setSelectedType(updatedType, false));
      });
    } else {
      dispatch(setSelectedType(type, true));
    }
  };

  const handleStatusChange = (status) => {
    if (Array.isArray(status)) {
      status.forEach((item) => {
        let updatedStatus =
          item === "ACTIVE" ? "Active" : item === "AVAILABLE" ? "Available": item === "ENABLED" ? "Enabled": item === "PAUSED" ? "Paused" : item === "REMOVED" ? "Removed" : item === "ARCHIVED" ? "Archived" : item === "PUBLISHED" ? "Published" : item;
        dispatch(setSelectedStatus(updatedStatus, false));
      });
    } else {
      dispatch(setSelectedStatus(status, true));
    }
  };

  const handlePerformanceChange = (performance_value) => {
    if (Array.isArray(performance_value)) {
      performance_value.forEach((item) => {
        dispatch(setSelectedPerformance(item, false));
      });
    } else {
      dispatch(setSelectedPerformance(performance_value, true));
    }
  };

  return {
    handleCategoryChange,
    handlePlatformsChange,
    handleTypesChange,
    handleStatusChange,
    handlePerformanceChange,
  };
};

export default useCustomDispatch;
