import React, {useState} from "react";
import Spinner from "../../../components/spinner";
import axios from "axios";
import {Modal} from "react-bootstrap";
import config from "../../../config.json";
import whiteLabel from "../../../assets/whitelabelConfig";

const Clients = ({
  fetchClientData,
  clientData,
  newClient,
  setNewClient,
  clientToEdit,
  setClientToEdit,
  setIsLoading,
  isLoading,
  setShowSuccessMessage,
  setShowErrorMessage,
  showErrorMessage,
  setMessage,
  Message,
  setFormValid,
  formValid,
  clearFormData,
  handleChange,
  handleFormChange,
}) => {
  const [showError, setShowError] = useState(false);

  const [showClientModal, setShowClientModal] = useState(false);
  const [showClientEditModal, setShowEditClientModal] = useState(false);

  const AddClient = (e) => {
    e.preventDefault();
    setIsLoading(true);
    const authToken = localStorage.getItem("token");

    const formData = new FormData();
    formData.append("name", newClient.name);
    formData.append("is_active", newClient.is_active);
    formData.append("logo", newClient.logo);

    axios
      .post(`${config.serviceUrl}/onboarding/add-client`, formData, {
        headers: {
          Authorization: authToken,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        // Refresh client data after adding the client
        fetchClientData();
        handleCloseClientModal();

        setTimeout(() => {
          setIsLoading(false);
          setShowSuccessMessage(true);
          setMessage(response.data.message);

          // Hide toast after 3 seconds
          setTimeout(() => {
            setShowSuccessMessage(false);
          }, 3000);
        }, 3000);
      })
      .catch((error) => {
        setNewClient({name: "", isActive: false, logo: null});
        setIsLoading(false);
        setShowErrorMessage(true);
        setMessage(error.response.data.error);
        // console.error("Error adding client:", error);
      })
      .finally(() => {
        setFormValid(false);
      });
  };

  const EditClient = (e, toggleIsActive) => {
    e.preventDefault();
    setIsLoading(true);
    const authToken = localStorage.getItem("token");
    let updatedIs_Active = clientToEdit.is_active;
    if (toggleIsActive !== undefined) {
      updatedIs_Active = !toggleIsActive;
    }
    // console.log(clientToEdit)
    const formData = new FormData();
    formData.append("is_active", updatedIs_Active);
    formData.append("client_id", clientToEdit.id);
    if (toggleIsActive === undefined) {
      formData.append("name", clientToEdit.name);
      formData.append("logo", clientToEdit.update_logo);
    }
    // console.log(formData)
    axios
      .put(`${config.serviceUrl}/onboarding/update-client`, formData, {
        headers: {
          Authorization: authToken,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        // Refresh client data after adding the client
        fetchClientData();
        // Reset the form fields
        setClientToEdit({name: "", isActive: false, update_logo: null});
        setShowError(false);
        setTimeout(() => {
          setIsLoading(false);
          setShowSuccessMessage(true);
          setMessage(response.data.message);

          // Hide toast after 3 seconds
          setTimeout(() => {
            setShowSuccessMessage(false);
          }, 3000);
        }, 3000);
      })
      .catch((error) => {
        setIsLoading(false);
        setShowErrorMessage(true);
        setMessage(error.response.data.error);
        // console.error("Error updating client:", error);
        // Hide toast after 3 seconds
        setTimeout(() => {
          setShowErrorMessage(false);
        }, 3000);
      });
  };

  const SetEditClient = (clientId, clientName, is_active) => {
    setClientToEdit({id: clientId, name: clientName, is_active: is_active});
  };

  const handleCloseClientModal = () => {
    setShowError(false);
    clearFormData("addClient");
    setShowClientModal(false);
  };

  const handleCloseClientEditModal = () => {
    setShowError(false);
    clearFormData("editClient");
    setShowEditClientModal(false);
  };

  const handleshowClientModal = () => setShowClientModal(true);

  const handleshowClientEditModal = (client) => {
    SetEditClient(client.id, client.name, client.is_active);
    setShowEditClientModal(true);
  };

  const handleFileChange = (e, form) => {
    setShowError(false);
    const file = e.target.files[0];
    const fileName = file.name;
    if (fileName && fileName.includes(" ")) {
      setShowError(true);
      e.target.value = null; // Clear the input field
      return;
    }
    if (form === "update") {
      setClientToEdit({
        ...clientToEdit,
        update_logo: e.target.files[0],
      });
    } else {
      setNewClient({
        ...newClient,
        logo: e.target.files[0],
      });
    }
  };

  return (
    <div className="text-center mx-3">
      <div className="row">
        <div className="col-md-6 col-6 d-flex justify-content-start my-2">
          <h4>Clients</h4>
        </div>
        <div className="col-md-6 col-6 d-flex justify-content-end my-2">
          <button className="btn btn-primary" onClick={handleshowClientModal}>
            <i className="bi bi-person-fill-add mx-1"></i>
            OnBoard Client
          </button>
        </div>
      </div>
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center mt-4 pt-2">
          <Spinner />
        </div>
      ) : (
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-11">
              <table className="table table-hover table-bordered border-secondary border-2 custom-table">
                <thead>
                  <tr>
                    <th>Sr. No</th>
                    <th>Name</th>
                    <th>Logo</th>
                    <th>Status</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {clientData.map((client, index) => (
                    <tr key={client.id}>
                      <td>{index + 1}</td>
                      <td>{client.name}</td>
                      <td>
                        <img
                          src={
                            client.logo_url
                              ? client.logo_url
                              : whiteLabel.alt_logo
                          }
                          className="rounded-circle me-2 mb-1"
                          width="40"
                          height="40"
                          alt="Client_Logo"
                        />
                      </td>
                      <td>{client.is_active ? "Active" : "Inactive"}</td>
                      <td>
                        <button
                          className="btn btn-secondary me-2"
                          title="Edit"
                          onClick={() => handleshowClientEditModal(client)}>
                          <i className="bi bi-pencil-square"></i>{" "}
                        </button>
                        {client.is_active ? (
                          <button
                            className="btn btn-danger"
                            data-bs-toggle="modal"
                            data-bs-target="#clientstatus"
                            title="Deactivate"
                            onClick={() =>
                              SetEditClient(
                                client.id,
                                client.name,
                                client.is_active
                              )
                            }>
                            <i className="bi bi-exclamation-circle-fill me-1"></i>
                          </button>
                        ) : (
                          <button
                            className="btn btn-primary"
                            data-bs-toggle="modal"
                            data-bs-target="#clientstatus"
                            title="Activate"
                            onClick={() =>
                              SetEditClient(
                                client.id,
                                client.name,
                                client.is_active
                              )
                            }>
                            <i className="bi bi-check-circle-fill me-1"></i>
                          </button>
                        )}
                        {/* <button
                      className="btn p-0 border-none text-danger"
                      data-bs-toggle="modal"
                      data-bs-target="#deleteclient"
                      onClick={() =>
                        SetDeleteClient(client.id, client.name)
                      }>
                    </button> */}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
      {/* AddClient Modal */}
      <Modal show={showClientModal} onHide={handleCloseClientModal}>
        <Modal.Header closeButton>
          <Modal.Title>Client Details</Modal.Title>
        </Modal.Header>
        <form onSubmit={AddClient} onChange={handleFormChange}>
          <div className="modal-body">
            <div className="mb-3">
              <label
                htmlFor="name"
                className="form-label d-flex justiy-content-start text-dark">
                Client Name:
              </label>
              <input
                type="text"
                className="form-control"
                id="name"
                name="name"
                value={newClient.name}
                onChange={(e) => handleChange(e, "client")}
                required
              />
              {showErrorMessage && (
                <div className="text-danger" role="alert">
                  {Message}
                </div>
              )}
            </div>
            <div className="mb-3">
              <label
                htmlFor="logo"
                className="form-label d-flex justiy-content-start text-dark">
                Client Logo:
              </label>
              <input
                type="file"
                accept="image/*, application/pdf"
                className="form-control"
                id="logo"
                name="logo"
                onChange={(e) => handleFileChange(e, "new")}
              />
              {showError && (
                <small className="form-text text-danger">
                  File name should not contain spaces.
                </small>
              )}
            </div>
          </div>
          <Modal.Footer>
            <button
              className="btn btn-secondary"
              onClick={handleCloseClientModal}>
              Close
            </button>
            <button
              type={formValid ? "submit" : "button"}
              className={`btn btn-primary ${!formValid && "disabled"}`}
              disabled={!formValid}>
              Save
            </button>
          </Modal.Footer>
        </form>
      </Modal>
      {/* EditClient Modal */}
      <Modal show={showClientEditModal} onHide={handleCloseClientEditModal}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Client</Modal.Title>
        </Modal.Header>
        <form onSubmit={EditClient} onChange={handleFormChange}>
          <div className="modal-body">
            <div className="mb-3">
              <label
                htmlFor="name"
                className="form-label d-flex justiy-content-start text-dark">
                Client Name:
              </label>
              <input
                type="text"
                className="form-control"
                id="name"
                name="name"
                value={clientToEdit.name}
                onChange={(e) => handleChange(e, "edit_client")}
                required
              />
            </div>
            <div className="mb-3">
              <label
                htmlFor="logo"
                className="form-label d-flex justiy-content-start text-dark">
                Client Logo:
              </label>
              <input
                type="file"
                className="form-control"
                accept="image/*, application/pdf"
                id="logo"
                name="logo"
                onChange={(e) => handleFileChange(e, "update")}
              />
              {showError && (
                <small className="form-text text-danger">
                  File name should not contain spaces.
                </small>
              )}
            </div>
          </div>
          <Modal.Footer>
            <button
              type="button"
              onClick={handleCloseClientEditModal}
              className="btn btn-secondary">
              Close
            </button>
            <button
              type={formValid ? "submit" : "button"}
              className={`btn btn-primary ${!formValid && "disabled"}`}
              disabled={!formValid}>
              Save
            </button>
          </Modal.Footer>
        </form>
      </Modal>
      {/* ClientStatus Modal */}
      <div
        className="modal fade"
        id="clientstatus"
        tabIndex="-1"
        aria-labelledby="clientstatusLabel"
        aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content text-dark">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <h5 className="modal-title" id="clientstatusLabel">
                {clientToEdit.is_active
                  ? `Are you sure you want to Deactivate" ${clientToEdit.name}`
                  : `Are you sure you want to Activate" ${clientToEdit.name}`}
              </h5>
            </div>
            <div className="modal-footer">
              <button
                className="btn btn-danger"
                data-bs-dismiss="modal"
                onClick={(e) => EditClient(e, clientToEdit.is_active)}>
                Yes
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                aria-label="Close">
                No
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Clients;
