import React, {useState, useEffect, useMemo} from "react";
import {Alert, Button, Form, Modal, Tab, Tabs} from "react-bootstrap";
import Spinner from "../../../components/spinner";
import axios from "axios";
import "../../../Styles/style.css";
import PerformanceForm from "./PerformanceForm";
import { capitalize, deepCopy, getValue, isObjWithKeys, setValue } from "../../../common/helpers/utils/DataHelper";
import { getAPIConfig, getIcon } from "../../../common/utils";
import ENDPOINTS from "../../../common/endpoints";
import {
  containsValidMetric,
  formatPerformanceMetricData,
  getFormDataTypeCount,
  getSubMediaPlatform,
  validateScores,
} from "./utils/utils";
import localisable from "../../../common/constants/localisable";
import { labelFormatter } from "../../../common/helpers/utils/Formatter";
import whiteLabel from "../../../assets/whitelabelConfig";

function PerformanceMetric({
  show,
  onHide,
  platform,
  setMessage,
  setShowSuccessMessage,
  setShowErrorMessage,
}) {
  const initialMetricScores = {
    excellent_score: "",
    good_score: "",
    average_score: "",
    low_score: "",
  };
  const [isLoading, setIsLoading] = useState(false);
  const [metricData, setMetricData] = useState({});
  const [isEditMode, setIsEditMode] = useState(false);
  const [formData, setFormData] = useState({});
  const [activeSubPlatformTab, setActiveSubPlatform] = useState("");
  const [activeCategoryTab, setActiveCategoryTab] = useState("");
  const [showValidationError, setShowValidationError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isAutoPopulateCheckboxVisible, setIsAutoPopulateCheckboxVisible] = useState(false);
  const [showSubmitButton, setShowSubmitButton] = useState(true);
  const [doesUseDefaultPlatformMetricExists, setDoesUseDefaultPlatformMetricExists] = useState(false); // to add spacing conditionally
  const [lastUpdatedUserDetail, setLastUpdatedUserDetail] = useState({});
  const [errors, setErrors] = useState({});

  const apiHeaderConfig = useMemo(() => getAPIConfig(), []);

  const performanceMetricFieldPath = [platform, activeSubPlatformTab, activeCategoryTab].join('.');

  const fetchMetricFields = (platform) => {
    const body = {
      media_platform: platform,
    };
    setIsLoading(true);
    axios
      .post(ENDPOINTS.PERFORMANCE_METRIC.getFields, body, apiHeaderConfig)
      .then((response) => {
        const data = response.data;
        const subPlatformData = data[platform]
        const subPlatform = getValue(Object.keys(data[platform] || {}), '0') || ''
        const categoryData = subPlatformData[subPlatform]
        const activeCategory = getValue(Object.keys(categoryData), '0') || ''
        setActiveSubPlatform(subPlatform);
        setActiveCategoryTab(activeCategory);
        setMetricData(data);
      })
      .catch((error) => {
        console.error("Error fetching metric fields:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchMetricFields(platform);
    // eslint-disable-next-line
  }, [platform]);

  useEffect(() => {
    const subPlatformData = metricData[platform] || {}
    const categoryData = subPlatformData[activeSubPlatformTab] || {};
    const activeCategory = getValue(Object.keys(categoryData), '0') || ''
    setActiveCategoryTab(activeCategory);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeSubPlatformTab])

  useEffect(() => {
    const controller = getMetricConfig();
    setIsEditMode(false);

    let useDefaultPerformanceMetricExists = false;
    const availableCategories = getValue(metricData, performanceMetricFieldPath) || {};
    for(const details of Object.values(availableCategories)){
      useDefaultPerformanceMetricExists = useDefaultPerformanceMetricExists || details.has_default_performance_value
    }
    setDoesUseDefaultPlatformMetricExists(useDefaultPerformanceMetricExists);
    setErrors({});
    return () => {
      // Cleanup: cancel the request if it's still in progress
      controller.abort();
    };
    // eslint-disable-next-line
  }, [activeSubPlatformTab, activeCategoryTab])

  const handleChange = (event, platform, subPlatform, category, type) => {
    const { name, value, checked, type: inputType } = event.target;
    const path = [platform, subPlatform, category, ...(type ? [type] : [])].join('.');
    setFormData(prevState => {
      const modifiedStateValue = {...prevState};
      const prevStateTypeValue = getValue(prevState, path) || {};
      let valueToSet;
      if (name === 'replicate_data') {
        let commonValueToSet = {};
        const prevStateCategoryData = getValue(modifiedStateValue, path) || {}; 
        const availableCategories = getValue(metricData, path) || {} // available categories in metric fields
        Object.keys(prevStateCategoryData).forEach(prevStateEachType => {
          const currentTypeData = getValue(prevStateCategoryData, prevStateEachType);
          if(currentTypeData && containsValidMetric(currentTypeData)){
            commonValueToSet = currentTypeData
          };
        })
        commonValueToSet = deepCopy(commonValueToSet)
        delete commonValueToSet.id // delete id to avoid id overriden in other asset type
        valueToSet = Object.keys(availableCategories).reduce((acc, eachType) => {
          const availableMetricFieldTypeWise = getValue(availableCategories, [eachType, 'metrics'].join('.')) || []; // to check if metric field exists in the other types where we will be copying our data
          const isMetricFieldPresent = availableMetricFieldTypeWise.includes(commonValueToSet.metric_field)
          const prevStateTypeData = getValue(prevStateCategoryData, eachType) || {} 
          const isNewEntry = !getValue(prevState, [path, eachType].join('.'));
          const commonNewEntriyFields = {platform, subPlatform, category}
          if (isMetricFieldPresent){
            acc[eachType] = { ...prevStateTypeData, ...commonValueToSet, ...(isNewEntry && commonNewEntriyFields ) };
          } else if (commonValueToSet.use_platform_metric) {
            acc[eachType] = { ...prevStateTypeData, ...initialMetricScores, use_platform_metric: commonValueToSet.use_platform_metric || false, metric_field: '', ...(isNewEntry && commonNewEntriyFields ) }
          } else {
            acc[eachType] = { ...prevStateTypeData, ...initialMetricScores, metric_field: 'not_applicable', ...(isNewEntry && commonNewEntriyFields ) }
          }
          return acc
        }, {});
       } else if (name === 'use_platform_metric') {
        valueToSet = {...prevStateTypeValue, ...initialMetricScores, metric_field: "", [name]: checked}
      } else if (name === 'metric_field' && value === 'not_applicable'){
        valueToSet = {...prevStateTypeValue, ...initialMetricScores, [name]: value}
      } else {
        valueToSet = {...prevStateTypeValue, [name]: inputType === 'checkbox' ? checked : value }
      }
      setValue(modifiedStateValue, path, valueToSet)
      const formDataTypeCount = getFormDataTypeCount(getValue(modifiedStateValue, [platform, subPlatform, category].join('.')) || {})
      const availableCategories = getValue(metricData, performanceMetricFieldPath) || {};
      const availableAssetTypesCount = Object.keys(availableCategories).length

      setIsAutoPopulateCheckboxVisible(availableAssetTypesCount > 1 ? formDataTypeCount === 1 : false);
      return modifiedStateValue;
    }
    );
  }

  const getMetricConfig = () => {
    const controller = new AbortController(); // Create an AbortController instance
    const signal = controller.signal; // Get the signal from the controller

    setIsLoading(true);
    if (!activeCategoryTab) return controller;
    const body = {
      sub_media_platform: activeSubPlatformTab || getSubMediaPlatform(platform),
      category: activeCategoryTab,
    };
    axios
      .post(ENDPOINTS.PERFORMANCE_METRIC.getConfig, body, {...apiHeaderConfig, signal})
      .then((response) => {
        const data = response.data;
        const formattedCategoryWiseData = {};
        const doesConfigExistsAlready = getValue(data, 'is_config_exist') || false;
        setIsAutoPopulateCheckboxVisible(data.length === 1);
        setLastUpdatedUserDetail(data.updated_by || {})
        const configs = getValue(data, 'config') || [];
        const configAssetTypeSet = new Set(configs);
        configs.forEach((eachData = {}) => {
          const { asset_type, use_platform_metric, ...rest } = eachData;
          formattedCategoryWiseData[asset_type] = { use_platform_metric, ...rest };
          configAssetTypeSet.add(asset_type);
        })
        
        if (doesConfigExistsAlready){
          const availableCategories = getValue(metricData, performanceMetricFieldPath) || {};
          const unavailableTypesData = Object.keys(availableCategories).filter((assetType) => !configAssetTypeSet.has(assetType))
          unavailableTypesData.forEach(eachType => {
            formattedCategoryWiseData[eachType] = { ...initialMetricScores, use_platform_metric: false, metric_field: 'not_applicable' };
          })
        }
        const modifiedFormData = {
          [platform]: {
            [activeSubPlatformTab]: {
              [activeCategoryTab]: formattedCategoryWiseData
            }
          }
        }
        setFormData(modifiedFormData);
        setShowSubmitButton(!isObjWithKeys(formattedCategoryWiseData));
        setIsLoading(false);
      })
      .catch((error) => {
        if(!axios.isCancel(error)) console.error("Error generating authentication URI:", error);
        setIsLoading(false);
      });
    return controller; // Return the controller so it can be used to cancel the request
  };

  const addMetricConfig = () => {
    setIsLoading(true);
    const platformData = formData[platform] || {};
    const subPlatformData = platformData[activeSubPlatformTab] || {};
    const categoryData = subPlatformData[activeCategoryTab] || {};

    const body = {
      'config_data': formatPerformanceMetricData(
        categoryData,
        activeSubPlatformTab, activeCategoryTab
      )
    };
    axios
      .put(ENDPOINTS.PERFORMANCE_METRIC.addConfig, body, apiHeaderConfig)
      .then((response) => {
        setShowSuccessMessage(true);
        setMessage(response.data.message);
        setTimeout(() => {
          setShowSuccessMessage(false);
        }, 3000);
        setFormData({});
        onHide();
        setIsEditMode(false);
      })
      .catch((error) => {
        setShowValidationError(true);
        setErrorMessage(capitalize(labelFormatter(error?.response?.data?.error || '')));
        setTimeout(() => {
          setShowValidationError(false);
        }, 3000);
      })
      .finally(() => {
        setIsLoading(false);
        // onHide();
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault()
    const performanceFieldValues = getValue(formData, performanceMetricFieldPath) || {};
    const isValid = Object.keys(performanceFieldValues).map(eachType => {
      return validateScores(
        performanceFieldValues[eachType],
        `${performanceMetricFieldPath}.${eachType}`,
        setShowValidationError, setErrorMessage, setFormData
      )
    });
    if (!isValid.every(element => element === true)) {
      return; 
    }
    addMetricConfig();
  }

  const renderTabs = (data) => {
    return (
      data &&
      Object.keys(data).map((platformKey) => {
        const platformData = data[platformKey];
        return (
          <Tab
            eventKey={platformKey}
            title={
              <>{platformKey !== 'TradeDesk' ?  <i className={`bi bi-${platformKey.toLowerCase()} me-2`}></i> :
              <img
                src={whiteLabel.TradeDesk_logo}
                alt="tradedesk_logo"
                className="me-2 mb-1"
                style={{height: "20px", width: "20px"}}
            />}
            {platformKey}
              </>
            }
            key={platformKey}
          >
            <Tabs
              className="custom-tab-nav border-0 mb-3"
              onSelect={(e) => setActiveSubPlatform(e)}
              activeKey={activeSubPlatformTab}
              justify
            >
              {platformData &&
                Object.keys(platformData).map((subPlatformKey) => {
                  const subPlatformData = platformData[subPlatformKey];
                  return (
                    <Tab
                      eventKey={subPlatformKey}
                      className="custom-tab-nav border-0 mb-2"
                      title={
                        <>
                          <i
                            className={`bi bi-${subPlatformKey.toLowerCase()} me-2`}></i>
                          {capitalize(labelFormatter(subPlatformKey), true)}
                        </>
                      }
                      key={subPlatformKey}
                    >
                      <Tabs
                        className="custom-tab-nav border-0 mb-2"
                        onSelect={(e) => setActiveCategoryTab(e)}
                        activeKey={activeCategoryTab}
                        >
                        {subPlatformData &&
                          Object.keys(subPlatformData).map((categoryKey) => {
                            const categoryData = subPlatformData[categoryKey];
                            return (
                              <Tab
                                eventKey={categoryKey}
                                title={categoryKey}
                                key={categoryKey}>
                                <div className="mt-3 col-md row">
                                  {categoryData &&
                                    Object.keys(categoryData).map((typeKey) => {
                                      const typeData = categoryData[typeKey] || {};
                                      return (
                                        <div className="col" key={`${platformKey}-${subPlatformKey}-${categoryKey}-${typeKey}`}>
                                          <PerformanceForm
                                            key={`${activeSubPlatformTab}-${activeCategoryTab}`}
                                            showcheckbox={typeData.has_default_performance_value}
                                            handleChange={handleChange}
                                            metricOptions={typeData.metrics}
                                            formData={formData}
                                            isEditMode={isEditMode}
                                            typeKey={typeKey}
                                            platform={platformKey}
                                            subPlatform={subPlatformKey}
                                            category={categoryKey}
                                            activeTabData={{'subPlatform': activeSubPlatformTab, 'category': activeCategoryTab}}
                                            showSubmitButton={showSubmitButton}
                                            setErrors={setErrors}
                                            errors={errors}
                                          />
                                        </div>
                                      );
                                    })}
                                </div>
                              </Tab>
                            );
                          })}
                      </Tabs>
                    </Tab>
                  );
                })}
            </Tabs>
          </Tab>
        );
      })
    );
  };

  const getTooltip = (title, tooltipIconText='info') => {
    return (
      <span data-toggle="tooltip" title={title}><i className={`bi ${getIcon(tooltipIconText)}`}></i></span>
    );
  }

  const renderDataNotAvailableMessage = () => {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <div className="p-2">{localisable.noDataAvailable}</div>
      </div>
    )
  }

  const isMetricFieldAvailable = isObjWithKeys(metricData);
  const hasError = isObjWithKeys(errors);

  return (
    <Modal
      show={show}
      onHide={onHide}
      backdrop="static"
      keyboard={false}
      size="lg"
    >
    <form onSubmit={handleSubmit}>
      <Modal.Header closeButton>
        <Modal.Title>{!isMetricFieldAvailable &&  !isLoading ? capitalize(platform) : localisable.performanceMetric}</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ minHeight: '50vh' }}>
      {isLoading ? (
          <Spinner />
        ) : isMetricFieldAvailable ? (
          <div className="container-fluid">
            <div className="row">
              <div className="col-3 border-end border-2">
                <div className="sticky-top">
                  <h6 className="mt-3 mb-4">Platform</h6>
                  <h6 className="mt-4 pt-1">Sub Platform</h6>
                  <h6 className="mt-4 pt-3">Category</h6>
                  <h6 className="mt-3 pt-3">Type</h6>
                  {doesUseDefaultPlatformMetricExists && <h6 className="mt-5 pt-2">{""}</h6>}
                  <h6 className="mt-3 pt-2">Metric Fields {getTooltip(localisable.metricFieldTooltipTitle)}</h6>
                  <h6 className="mt-4 pt-4">Excellent Score {getTooltip(localisable.excellentScoreTooltipTitle)}</h6>
                  <h6 className="mt-4 pt-4">Good Score {getTooltip(localisable.goodScoreTooltipTitle)}</h6>
                  <h6 className="mt-4 pt-4">Average Score {getTooltip(localisable.averageScoreTooltipTitle)}</h6>
                  <h6 className="mt-4 pt-4">Low Score {getTooltip(localisable.lowScoreTooltipTitle)}</h6>
                </div>
              </div>
              <div className="col-9">
                <Tabs
                  className="mb-3"
                  justify
                  defaultActiveKey={isMetricFieldAvailable && Object.keys(metricData)[0]}
                  id="platform-tabs">
                  {renderTabs(metricData)}
                </Tabs>
                <div className="row mt-3">
                  {isAutoPopulateCheckboxVisible && (
                      <Form.Group controlId="replicate_data">
                        <Form.Check
                          key={`${platform}-${activeSubPlatformTab}-${activeCategoryTab}-replicate_data`}
                          className="text-align-center"
                          type="checkbox"
                          name="replicate_data"
                          label={localisable.copyMetricData}
                          onChange={(e) => handleChange(e, platform, activeSubPlatformTab, activeCategoryTab)}
                          disabled={!isEditMode && !showSubmitButton}
                        >
                          </Form.Check>
                      </Form.Group>
                    )}
                    <p>Last Updated By: {lastUpdatedUserDetail.name || localisable.notSetYet}</p>
                  </div>
              </div>
            </div>
          </div>
        ) : renderDataNotAvailableMessage()}
      </Modal.Body>
      {isMetricFieldAvailable &&
      <Modal.Footer>
        {showValidationError && (
          <Alert variant="danger" className="mt-1">
            {errorMessage}
          </Alert>
        )}
        
        {showSubmitButton ? (
          <Button key={`submit-form`} variant="primary" className="mt-2" type="submit" disabled={isLoading || hasError}>
            Submit
          </Button>
        ) : !isEditMode ? (
          <Button variant="primary" className="mt-2" onClick={() => setIsEditMode(true)}>
            Edit
          </Button>
        ) : (
          <>
            {isEditMode && (
              <>
                <Button
                  key={`cancel-${isEditMode}`}
                  variant="secondary"
                  className="mt-2 me-2"
                  disabled={isLoading}
                  onClick={() => setIsEditMode(prevState => !prevState)}>
                  Cancel
                </Button>
                <Button
                  key={`update-${isEditMode}`}
                  variant="primary"
                  className="mt-2"
                  type="submit"
                  disabled={isLoading || hasError}
                >
                  Update
                </Button>
              </>
            )}
          </>
        )}
      </Modal.Footer>
      }
    </form>
    </Modal>
  );
}

export default PerformanceMetric;
