import React, {useState, useEffect} from "react";
import {Modal, Accordion} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import DatePicker from "react-datepicker";
import Spinner from "../../../components/spinner";
import axios from "axios";
import config from "../../../config.json";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../../Styles/style.css";

function JourneyMap(props) {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [Message, setMessage] = useState("");
  const [journeyMaps, setJourneyMaps] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [mapToDelete, setMapToDelete] = useState(false);

  const getJourneyMaps = () => {
    setIsLoading(true);
    const authToken = localStorage.getItem("token");
    const configuration = {
      headers: {
        Authorization: authToken,
        "Content-Type": "application/json",
      },
    };
    axios
      .get(
        `${config.serviceUrl}/journey_maps/get-all-journey-map`,
        configuration
      )
      .then((response) => {
        const formattedJourneyMaps = response.data.data.map((map) => {
          // Convert campaign_start_date and campaign_end_date to desired format
          const formattedStartDate = new Date(map.campaign_start_date).toLocaleDateString("en-US", {
            month: "short",
            day: "2-digit",
            year: "numeric",
          });
          const formattedEndDate = new Date(map.campaign_end_date).toLocaleDateString("en-US", {
            month: "short",
            day: "2-digit",
            year: "numeric",
          });
          return {
            ...map,
            campaign_start_date: formattedStartDate,
            campaign_end_date: formattedEndDate,
          };
        });
        setJourneyMaps(formattedJourneyMaps);
      })
      .catch((error) => {
        console.error("Error fetching journey maps:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getJourneyMaps();
  }, []);

  const handleAddSteps = (map) => {
    // Navigate to "/journeymap/addsteps" when Add Steps button is clicked, passing the map details
    navigate("/journeymap/addsteps", { state: { journeyMap: map } });
  };

  const formatDateForBackend = (date) => {
    const date_obj = new Date(date);
    const utcDate = new Date(
      Date.UTC(
        date_obj.getFullYear(),
        date_obj.getMonth(),
        date_obj.getDate(),
        date_obj.getHours(),
        date_obj.getMinutes(),
        date_obj.getSeconds()
      )
    );
    const year = utcDate.getUTCFullYear();
    const month = (utcDate.getUTCMonth() + 1).toString().padStart(2, "0");
    const day = utcDate.getUTCDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const [formData, setFormData] = useState({
    name: "",
    description: "",
    category: "",
    product: "",
    target_audience: "",
    objective: "",
    campaign_start_date: new Date(),
    campaign_end_date: new Date(),
  });

  // State for form field errors
  const [formErrors, setFormErrors] = useState({
    name: "",
    description: "",
    category: "",
    product: "",
    target_audience: "",
    objective: "",
  });

  const handleChange = (e) => {
    const {name, value} = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    // Clear error message when user starts typing in the field
    setFormErrors({
      ...formErrors,
      [name]: "",
    });
  };

  const handleStartDateChange = (date) => {
    setFormData({
      ...formData,
      campaign_start_date: date,
    });
  };

  const handleEndDateChange = (date) => {
    setFormData({
      ...formData,
      campaign_end_date: date,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Check for missing fields and set error messages
    const errors = {};
    Object.entries(formData).forEach(([key, value]) => {
      if (value === "") {
        errors[key] = `${
          key.charAt(0).toUpperCase() + key.slice(1)
        } is required`;
      }
    });
    // If there are errors, prevent form submission
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
    } else {
      const authToken = localStorage.getItem("token");
      const configuration = {
        headers: {
          Authorization: authToken,
          "Content-Type": "application/json",
        },
      };
      const formatted_start_date = formatDateForBackend(
        formData.campaign_start_date
      );
      const formatted_end_date = formatDateForBackend(
        formData.campaign_end_date
      );
      // Create a new object with the formatted dates
      const updatedFormData = {
        ...formData,
        campaign_start_date: formatted_start_date,
        campaign_end_date: formatted_end_date,
      };
      // Prepare the request body
      const body = JSON.stringify(updatedFormData);
      axios
        .post(
          `${config.serviceUrl}/journey_maps/add-journey-map`,
          body,
          configuration
        )
        .then((response) => {
          setMessage(response.data.message);
          setShowSuccessMessage(true);
        })
        .catch((error) => {
          // console.log(error.response.data.error);
          setMessage(error.response.data.error);
          setShowErrorMessage(true);
        })
        .finally(() => {
          handleClose();
          getJourneyMaps();
          setTimeout(() => {
            setShowSuccessMessage(false);
            setShowErrorMessage(false);
          }, 3000);
        });
    }
  };

  const handleDelete = (map) => {
    const authToken = localStorage.getItem("token");
      const configuration = {
        headers: {
          Authorization: authToken,
          "Content-Type": "application/json",
        },
      };
      const id = +map.id;
      axios
        .delete(`${config.serviceUrl}/journey_maps/delete-journey-map/${id}`, configuration)
        .then((response) => {
          setMessage(response.data.message);
          setShowSuccessMessage(true);
        })
        .catch((error) => {
          // console.log(error.response.data.error);
          setMessage(error.response.data.error);
          setShowErrorMessage(true);
        })
        .finally(() => {
          hideDeleteModal();
          getJourneyMaps();
          setTimeout(() => {
            setShowSuccessMessage(false);
            setShowErrorMessage(false);
          }, 3000);
        });
  }

  const openDeleteModal = (map) => {
    setMapToDelete(map);
    setShowDeleteModal(true);
  };

  const hideDeleteModal = () => setShowDeleteModal(false);

  const handleShow = () => setShowModal(true);
  
  const handleClose = () => {
    setShowModal(false);
    // Reset formErrors when modal is closed
    setFormErrors({
      name: "",
      description: "",
      category: "",
      product: "",
      target_audience: "",
      objective: "",
    });
    // Reset formData when modal is closed
    setFormData({
      name: "",
      description: "",
      category: "",
      product: "",
      target_audience: "",
      objective: "",
      campaign_start_date: new Date(),
      campaign_end_date: new Date(),
    });
  };

  return (
    <div
      className="container-fluid position-relative z-index-1 mb-2"
      onScroll={props.handleScroll}
      style={{top: "58px"}}>
      <div className="text-white mt-4 d-flex align-items-center">
        <div className="d-flex">
          <h5>Journey Maps</h5>
        </div>
        {showSuccessMessage && (
          <div className="col d-flex justify-content-center">
            <div className="alert alert-success p-2 m-0 fs-5" role="alert">
              {Message}
            </div>
          </div>
        )}
        {showErrorMessage && (
          <div className="col d-flex justify-content-start">
            <div className="alert alert-danger p-2 m-0 fs-5" role="alert">
              {Message}
            </div>
          </div>
        )}
        <div className="d-flex ms-auto">
          <button className="btn btn-primary" onClick={handleShow}>
            <i className="bi bi-plus-lg"></i> Journey Map
          </button>
        </div>
      </div>
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center">
          <Spinner />
        </div>
      ) : (
        <div className="mt-3">
          <table className="table table-hover table-bordered border-secondary border-2 custom-table">
            <thead>
              <tr>
                <th className="text-center">Name</th>
                <th className="text-center">Description</th>
              </tr>
            </thead>
            <tbody>
              {journeyMaps.map((map) => (
                <tr key={map.id}>
                  <td className="text-center">{map.name}</td>
                  <td>
                    <Accordion className="custom-accordion">
                      <Accordion.Item eventKey={map.id}>
                        <Accordion.Header title={map.description}>
                          <div className="d-flex flex-column">
                            <span>
                              {map.description
                                .split(" ")
                                .slice(0, 15)
                                .join(" ")}
                              {map.description.split(" ").length > 15
                                ? "..."
                                : ""}
                            </span>
                          </div>
                        </Accordion.Header>
                        <Accordion.Body>
                          <div className="row">
                            <div className="col">
                              <p>
                                <strong>Category:</strong> {map.category}
                              </p>
                              <p>
                                <strong>Product:</strong> {map.product}
                              </p>
                              <p>
                                <strong className="me-2">
                                  Campaign Date(s):
                                </strong>
                                <br />
                                <b>Start Date:</b>{" "}
                                {map.campaign_start_date}{" "}
                                <b>End Date:</b>{" "}
                                {map.campaign_end_date}
                              </p>
                            </div>
                            <div className="col">
                              <p>
                                <strong className="me-2">Target Audience:
                                  </strong>
                                {map.target_audience}
                              </p>
                              <p>
                                <strong>Objective:</strong> {map.objective}
                              </p>
                              <p className="d-flex justify-content-end">
                                <button
                                  className="btn btn-primary"
                                  onClick={() => handleAddSteps(map)}>
                                  <i className="bi bi-pencil-square me-1"></i> Edit
                                </button>
                                <button
                                  className="btn btn-danger ms-2"
                                  onClick={() => openDeleteModal(map)}>
                                  <i className="bi bi-trash me-1"></i> Delete
                                </button>
                              </p>
                            </div>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Create Journey Map</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit} className="d-flex flex-column gap-3">
            <div className="form-group">
              <label htmlFor="formName">Name</label>
              <input
                type="text"
                className="form-control"
                id="formName"
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder={formErrors.name || "Enter name"}
              />
              {formErrors.name && (
                <small className="text-danger">{formErrors.name}</small>
              )}
            </div>

            <div className="form-group">
              <label htmlFor="formDescription">Description</label>
              <textarea
                className="form-control"
                id="formDescription"
                name="description"
                value={formData.description}
                onChange={handleChange}
                rows={3}
                placeholder={formErrors.description || "Enter description"}
              />
              {formErrors.description && (
                <small className="text-danger">{formErrors.description}</small>
              )}
            </div>

            <div className="form-group">
              <label htmlFor="formCategory">Category</label>
              <input
                type="text"
                className="form-control"
                id="formCategory"
                name="category"
                value={formData.category}
                onChange={handleChange}
                placeholder={formErrors.category || "Enter category"}
              />
              {formErrors.category && (
                <small className="text-danger">{formErrors.category}</small>
              )}
            </div>

            <div className="form-group">
              <label htmlFor="formProduct">Product</label>
              <input
                type="text"
                className="form-control"
                id="formProduct"
                name="product"
                value={formData.product}
                onChange={handleChange}
                placeholder={formErrors.product || "Enter product"}
              />
              {formErrors.product && (
                <small className="text-danger">{formErrors.product}</small>
              )}
            </div>

            <div className="form-group">
              <label htmlFor="formAudience">Target Audience</label>
              <input
                type="text"
                className="form-control"
                id="formAudience"
                name="target_audience"
                value={formData.target_audience}
                onChange={handleChange}
                placeholder={
                  formErrors.target_audience || "Enter Target Audience"
                }
              />
              {formErrors.target_audience && (
                <small className="text-danger">
                  {formErrors.target_audience}
                </small>
              )}
            </div>

            <div className="form-group">
              <label htmlFor="formObjectives">Objective</label>
              <input
                type="text"
                className="form-control"
                id="formObjectives"
                name="objective"
                value={formData.objective}
                onChange={handleChange}
                placeholder={formErrors.objective || "Enter objective"}
              />
              {formErrors.objective && (
                <small className="text-danger">{formErrors.objective}</small>
              )}
            </div>

            <div className="form-group">
              <label htmlFor="formDates">Campaign Date(s)</label>
              <div className="d-flex">
                <span>
                  Start Date:
                  <DatePicker
                    className="form-control border-secondary p-1 mx-1"
                    selected={formData.campaign_start_date}
                    onChange={handleStartDateChange}
                    placeholderText="Start Date"
                    dateFormat="MMM dd, yyyy"
                    showMonthDropdown
                    showYearDropdown
                    scrollableYearDropdown
                    maxDate={new Date()}
                  />
                </span>
                <span className="ms-2">
                  End Date:
                  <DatePicker
                    className="form-control border-secondary p-1 mx-1"
                    selected={formData.campaign_end_date}
                    onChange={handleEndDateChange}
                    placeholderText="End Date"
                    dateFormat="MMM dd, yyyy"
                    showMonthDropdown
                    showYearDropdown
                    scrollableYearDropdown
                    minDate={formData.campaign_start_date ? new Date(formData.campaign_start_date) : null}
                    maxDate={new Date()}
                  />
                </span>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-primary"
            type="submit"
            onClick={handleSubmit}>
            Save Changes
          </button>
          <button className="btn btn-secondary" onClick={handleClose}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
      {/* Delete Modal */}
      <Modal show={showDeleteModal} onHide={hideDeleteModal}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Modal</Modal.Title>
        </Modal.Header>
        <div className="modal-body">
          <h5>Are you sure you want to delete ?</h5>
        </div>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={hideDeleteModal}>
            Cancel
          </button>
          <button
            className="btn btn-primary"
            onClick={() => handleDelete(mapToDelete)}>
            Save
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
export default JourneyMap;
