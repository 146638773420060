import config from '../config.json';

const ENDPOINTS = {
  PERFORMANCE_METRIC: {
    getConfig: `${config.serviceUrl}/performance-metric/get-performance-metric-config`,
    addConfig: `${config.serviceUrl}/performance-metric/upsert-performance-metric-config`,
    getFields: `${config.serviceUrl}/performance-metric/get-performance-metric-fields`,
  },
};

export default ENDPOINTS;