const deepCopy = object => JSON.parse(JSON.stringify(object));

const isValidObject = obj => (typeof obj === 'object' && obj !== null);

const isObjWithKeys = obj => (isValidObject(obj) ? Object.keys(obj).length > 0 : false);

const capitalize = (str = '', global = false) => (
  global ? str.replace(/\b\w/g, match => match.toUpperCase())
      : str.charAt(0).toUpperCase() + str.slice(1)
);

const getFieldName = (name) => {
  const delimiter = '.';
  return name.includes(delimiter) ? name.split(delimiter) : name;
};

const setValue = (obj, property, value) => {
    const localObj = obj;
    const fieldNames = getFieldName(property);
    if (Array.isArray(fieldNames)) {
        const lastField = fieldNames.pop();
        let traversedObject = {};
        fieldNames.forEach((fieldName) => {
            traversedObject = traversedObject && typeof traversedObject[fieldName] !== 'undefined'
                ? traversedObject[fieldName]
                : localObj[fieldName];
            if (!traversedObject) {
                traversedObject = { [fieldName]: {} };
            }
        });
        // Traversed n-1 fields
        if ((Array.isArray(traversedObject[lastField]) && isValidObject(traversedObject[lastField]))) {
            const curTraversedArray = traversedObject[lastField];
            curTraversedArray.forEach((element, index) => {
                if (isValidObject(element)) {
                    // Iterate through the value property to set
                    for (const prop in value) {
                        if (element.hasOwnProperty(prop)) {
                            // If property exists set value
                            // eslint-disable-next-line no-param-reassign
                            element[prop] = value[prop];
                        }
                    }
                } else {
                    traversedObject[lastField][index] = value[index];
                }
            });
        } else {
            traversedObject[lastField] = value;
        }
    } else {
        localObj[property] = value;
    }
};


const getValue = (obj, name) => {
  if (!obj || !name) {
      throw new TypeError('Invalid Parameter Values');
  }

  const objToTraverse = deepCopy(obj); // To avoid reference issue
  let traversedObject;
  const fieldNames = getFieldName(name);
  if (Array.isArray(fieldNames)) {
      traversedObject = objToTraverse[fieldNames[0]] || {};
      for (let index = 1; index < fieldNames.length; index += 1) {
          traversedObject = traversedObject[fieldNames[index]];
          if (!traversedObject) {
              break; // Can't traverse deeper
          }
      }
  } else {
      traversedObject = obj && obj[fieldNames];
  }
  return traversedObject;
};

const camelToSnakeCase = str => str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);

export { camelToSnakeCase, capitalize, deepCopy, getValue, getFieldName, isValidObject, isObjWithKeys, setValue };