import React, {useState} from "react";
import axios from "axios";
import config from "../../../../config.json";
import {renderBadge} from "../../utils/utils";
import {excludedPerformanceKeys, topPerformanceKeys } from './DetailsConfig'; 
import {formatKeys} from '../../utils/utils';

const Details_Performance = ({
  assetDetail,
  formData,
  getassetDetail,
  handleChange,
  setisLoading,
  setShowSuccessMessage,
  setShowErrorMessage,
  setMessage,
}) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const ExcludedKeys = excludedPerformanceKeys[assetDetail.media_platform ==="Offline_Media" ? assetDetail.media_platform : assetDetail.sub_media_platform] || [];
  console.log(ExcludedKeys)
  const insights = assetDetail.insights && typeof assetDetail.insights === 'object' ? assetDetail.insights : {};
  const platformTopKeys = topPerformanceKeys[assetDetail.sub_media_platform] || [];
  const orderedKeys = [];

  const filteredInsights = Object.entries(insights).filter(
    ([key]) => !ExcludedKeys.includes(key)
  );

  // Add topPerformanceKeys in specified order if they exist in filteredInsights
  if (platformTopKeys.length > 0) {
    // Add top keys in specified order if they exist in filteredInsights
    platformTopKeys.forEach((topKey) => {
      const index = filteredInsights.findIndex(([key]) => key === topKey);
      if (index !== -1) {
        orderedKeys.push(filteredInsights[index]);
        filteredInsights.splice(index, 1); // Remove the key from filteredInsights to avoid duplication
      }
    });
  }
  
  // Add the remaining keys in their original order
  orderedKeys.push(...filteredInsights);
  

  const UpdateDetails = (e) => {
    e.preventDefault();
    setisLoading(true);
    const authToken = localStorage.getItem("token");
    const configuration = {
      headers: {
        Authorization: authToken,
        "Content-Type": "application/json",
      },
    };
    const body = {
      asset_id: formData.id,
      performance_value: formData.performance_value,
    };
    // console.log("Submitting updated details:", body);
    axios
      .post(
        `${config.serviceUrl}/offline/update-offline-asset`,
        body,
        configuration
      )
      .then((response) => {
        getassetDetail();
        setShowSuccessMessage(true);
        setMessage(response.data.message);
        setTimeout(() => {
          setisLoading(false);
        }, 5000);
        // Hide toast after 5 seconds
        setTimeout(() => {
          setShowSuccessMessage(false);
        }, 5000);
      })
      .catch((error) => {
        setisLoading(false);
        setShowErrorMessage(true);
        setMessage(error.response.data.error);
        // Hide toast after 5 seconds
        setTimeout(() => {
          setShowErrorMessage(false);
        }, 5000);
        // console.error("Error uploading data:", error);
      });
  };

  return (
    <>
      <span className="d-flex align-items-center">
        <h3 className="mt-10px">Performance</h3>
        {assetDetail.media_platform === "Offline_Media" &&
          (isEditMode ? (
            <span className="ms-auto">
              <button className="btn btn-primary me-2 mb-1" onClick={UpdateDetails}>
                Save
              </button>
              <button
                className="btn btn-secondary mb-1"
                onClick={() => setIsEditMode(false)}>
                Cancel
              </button>
            </span>
          ) : (
            <button
              className="btn btn-primary ms-auto mb-1"
              onClick={() => setIsEditMode(true)}>
              <i className="bi bi-pencil-square"></i> Edit
            </button>
          ))}
      </span>
      <hr />
      <div className="profile-content">
        <div className="card-body">
          <div className="d-flex justify-content-center">
            <table className="d-flex container-fluid justify-content-center">
              <tbody className="container-fluid text-break">
                {assetDetail.media_platform === "Offline_Media" ? (
                  <tr className="d-flex mb-3">
                    <td className="col-6 fs-6 text-end">
                      <b>Performance:</b>
                    </td>
                    <td className="col-6 mx-2 d-flex align-items-center">
                      {isEditMode ? (
                        <>
                          <select
                            className="form-select performance-dropdown text-white"
                            id="performance_value"
                            name="performance_value"
                            value={formData.performance_value}
                            onChange={handleChange}>
                            <option value="1">Excellent</option>
                            <option value="2">Good</option>
                            <option value="3">Average</option>
                            <option value="4">Low</option>
                            <option value="5">Very Low</option>
                            <option value="0">Unknown</option>
                          </select>
                        </>
                      ) : (
                        <span className="fs-6">
                          {assetDetail.performance_value
                            ? renderBadge(assetDetail.performance_value)
                            : "Unknown"}
                        </span>
                      )}
                    </td>
                  </tr>
                ) : (
                  orderedKeys.flatMap(([key, value]) =>
                    Array.isArray(value)
                      ? value.map((item, index) => (
                          Object.entries(item).map(([subKey, subValue]) => (
                            <tr className="d-flex mb-3" key={`${key}-${index}-${subKey}`}>
                              <td className="col-6 text-end">
                                <b>{`${formatKeys(key)} ${formatKeys(subKey)}:`}</b>
                              </td>
                              <td className="col-6 mx-2">{subValue}</td>
                            </tr>
                          ))
                        ))
                      : (
                        <tr className="d-flex mb-3" key={key}>
                          <td className="col-6 text-end">
                            <b>{formatKeys(key)} :</b>
                          </td>
                          <td className="col-6 mx-2">{value}</td>
                        </tr>
                      )
                  )                                                                            
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default Details_Performance;
