import React, {useState, useEffect, useRef} from "react";
import Spinner from "../../../components/spinner";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import config from "../../../config.json";

const AssetUpload = (props) => {
  const [currentView, setCurrentView] = useState("Upload Asset");
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [Message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const fileInputRef = useRef(null);
  const [formData, setFormData] = useState({
    file: null,
    thumbnail: null,
    channel: "",
    performance_value: 0,
    status: "active",
    file_name: "",
    common_name: "",
    creation_date: "",
    asset_start_date: "",
    asset_end_date: "",
    filepath: "",
    file_owner: props.user.name,
    asset_type: "",
    extension: "",
    color_range: "",
    size: "",
    width: "",
    height: "",
    encoding: "",
    audio: "",
    duration: "",
    postContent: "",
    // performanceMeasurement: "",
    // documents: null,
  });

  const handleChange = (e) => {
    const {name, value} = e.target;
    const newValue = name === "performance_value" ? parseInt(value, 10) : value;
    setFormData({...formData, [name]: newValue});
  };

  const handleFileChange = (e) => {
    setShowErrorMessage(false);
    const file = e.target.files[0];
    const fileName = file.name;
    if (fileName.includes(" ")) {
      setShowErrorMessage(true);
      setMessage("File name should not contain spaces.")
      setTimeout(() => {
        setShowErrorMessage(false);
      }, 3000);
      e.target.value = null;
      return;
    }
    const errorDiv = document.getElementById("customError");
    if (errorDiv) {
      errorDiv.style.display = "none";
    }
    if (e.target.id === "fileInput" && e.target.value) {
      const size = file.size;
      const file_name = file.name;
      const extension = file_name.split(".").pop().toLowerCase();
      const file_type = file.type.toLowerCase();
      let asset_type;
      if (file_type.includes("pdf")) {
        asset_type = "pdf";
      } else if (file_type.includes("image")) {
        asset_type = "image";
      } else if (file_type.includes("video")) {
        asset_type = "video";
      } else if (file_type.includes("audio")) {
        asset_type = "audio";
      } else if (
        file_type.includes("msword") ||
        file_type.includes(
          "vnd.openxmlformats-officedocument.wordprocessingml.document"
        )
      ) {
        asset_type = "document";
      } else {
        asset_type = "";
      }
      setFormData({...formData, file, extension, size, file_name, asset_type});
    } else {
      setFormData({...formData, thumbnail: file});
    }
  };

  const handleDateChange = (date, field) => {
    if (date) {
      const formattedDate = `${date.toLocaleString("en-US", {
        month: "short",
        day: "2-digit",
        year: "numeric",
      })}`;
      setFormData((prevState) => ({
        ...prevState,
        [field]: formattedDate,
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [field]: "",
      }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Check if a file has been selected
    if (!fileInputRef.current.files.length) {
      // Display the custom error message
      const errorDiv = document.getElementById("customError");
      errorDiv.style.display = "block";

      // Focus on the input field
      fileInputRef.current.focus();
    } else {
      setIsLoading(true);
      const authToken = localStorage.getItem("token");
      const configuration = {
        headers: {
          Authorization: authToken,
          "Content-Type": "multipart/form-data",
        },
      };
      const formdata = new FormData();
      for (const key in formData) {
        const value = formData[key];
        if (value !== "") {
          formdata.append(key, value);
        }
      }
      // console.log(formData);
      axios
        .post(
          `${config.serviceUrl}/offline/upload-offline-asset`,
          formdata,
          configuration
        )
        .then((response) => {
          setIsLoading(false);
          handleClear();
          setShowSuccessMessage(true);
          setMessage(response.data.message);
          // Hide toast after 3 seconds
          setTimeout(() => {
            setShowSuccessMessage(false);
          }, 3000);
        })
        .catch((error) => {
          setIsLoading(false);
          handleClear();
          setShowErrorMessage(true);
          setMessage(error.response.data.error);
          // Hide toast after 3 seconds
          setTimeout(() => {
            setShowErrorMessage(false);
          }, 3000);
          // console.error("Error uploading data:", error);
        });
    }
  };

  const handleClear = (action) => {
    setFormData({
      file: null,
      thumbnail: null,
      channel: "",
      performance_value: 0,
      status: "active",
      file_name: "",
      common_name: "",
      creation_date: "",
      asset_start_date: "",
      asset_end_date: "",
      filepath: "",
      file_owner: props.user.name,
      asset_type: "",
      extension: "",
      color_range: "",
      size: "",
      width: "",
      height: "",
      encoding: "",
      audio: "",
      duration: "",
      postContent: "",
    });
    if (action === "clear_file") {
      document.getElementById("fileInput").value = "";
      document.getElementById("thumbnail").value = "";
    }
  };

  const [objectUrl, setObjectUrl] = useState(null);

  // Create object URL when formData.file changes
  useEffect(() => {
    if (formData.file) {
      const url = URL.createObjectURL(formData.file);
      setObjectUrl(url);
      return () => URL.revokeObjectURL(url); // Revoke object URL when component unmounts
    }
  }, [formData.file]);

  return (
    <div className="d-flex flex-column">
      <div
        className={
          "m-0 p-0 inner-nav position-fixed fixed-top row justify-content-end" +
          (props.isNavbarVisible ? " nav-up" : " nav-down")
        }
        style={{zIndex: "10"}}>
        {props.isSideBar && <div className="col-3 col-md-3 col-lg-2"></div>}
        <div
          className={`${
            props.isSideBar ? "col-9 col-md-9 col-lg-10" : "col"
          } transition-width p-0`}>
          {/* Navigation bar */}
          <nav className="navbar navbar-expand-lg navbar-dark bg-black border border-secondary">
            <div className="container-fluid">
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarNav"
                aria-controls="navbarNav"
                aria-expanded="false"
                aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse" id="navbarNav">
                <ul className="navbar-nav col-4">
                  {/* <li className="nav-item me-1">
                    <button
                      className={`btn nav-link ${
                        currentView === "Offline Assets"
                          ? "active"
                          : "text-secondary"
                      }`}
                      onClick={() => setCurrentView("Offline Assets")}>
                      Offline Assets
                    </button>
                  </li> */}
                  <li className="nav-item">
                    <button
                      className={`btn nav-link ${
                        currentView === "Upload Asset"
                          ? "active"
                          : "text-secondary"
                      }`}
                      onClick={() => setCurrentView("Upload Asset")}>
                      Upload Asset
                    </button>
                  </li>
                </ul>
                {showSuccessMessage && (
                  <div className="col d-flex justify-content-start fs-5">
                    <div className="alert alert-success p-2 m-0" role="alert">
                      {Message}
                    </div>
                  </div>
                )}
                {showErrorMessage && (
                  <div className="col d-flex justify-content-start fs-5">
                    <div className="alert alert-danger p-2 m-0" role="alert">
                      {Message}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </nav>
        </div>
      </div>
      <div className="position-relative text-white pb-4" style={{top: "125px"}}>
        {isLoading ? (
          <Spinner />
        ) : (
          <>
            {currentView === "Upload Asset" && (
              <div className="container">
                <form onSubmit={handleSubmit}>
                  <div className="d-flex justify-content-center">
                    <h4>Asset Details</h4>
                  </div>
                  <div className="row d-flex">
                    {/* Left Section: File Upload and Render */}
                    <div className="col-md-6">
                      <div className="bg-black py-1 px-2 rounded rounded-bottom-0 text-truncate">
                        File Name:{" "}
                        {formData.file && formData.file.name
                          ? formData.file.name
                          : null}
                      </div>
                      <div className="left-section rounded rounded-top-0 py-2 px-2">
                        <div className="mb-3 col-md-8">
                          <label
                            htmlFor="fileInput"
                            className="form-label text-white">
                            Upload File:
                          </label>
                          <input
                            type="file"
                            className="form-control asset-inputs"
                            id="fileInput"
                            onChange={handleFileChange}
                            accept="image/*, video/*, application/pdf, audio/*, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                            ref={fileInputRef}
                          />
                          <small className="form-text text-light">
                            File upload limit is 30 MB.
                          </small>
                          <div
                            id="customError"
                            className="invalid-feedback fs-6"
                            style={{display: "none"}}>
                            Please select a file.
                          </div>
                        </div>
                        {/* Render Uploaded File (if any) */}
                        <div className="mb-3">
                          {formData.file && (
                            <>
                              <h6>Uploaded File:</h6>
                              {formData.file.type.startsWith("image/") ? (
                                <img
                                  src={URL.createObjectURL(formData.file)}
                                  className="img-fluid w-100"
                                  alt="Uploaded File"
                                  style={{objectFit: "contain"}}
                                />
                              ) : formData.file.type.startsWith("video/") ? (
                                <video controls className="w-100">
                                  <source
                                    src={URL.createObjectURL(formData.file)}
                                    type={formData.file.type}
                                  />
                                  Your browser does not support the video tag.
                                </video>
                              ) : formData.file.type === "application/pdf" ? (
                                <iframe
                                  src={objectUrl}
                                  width="100%"
                                  height="500px"
                                  title="Uploaded PDF"
                                />
                              ) : (
                                <p>{formData.file.name}</p>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                    {/* Right Section: Remaining Data Fields */}
                    <div className="col-md-6 pb-4">
                      <div className="bg-black py-1 px-2 rounded rounded-bottom-0">
                        Details
                      </div>
                      <div className="right-section rounded rounded-top-0 py-2 px-2">
                        {/* FILE STATUS */}
                        <div className="mb-3 px-2">
                          <div className="label mb-3">
                            <h6>FILE STATUS</h6>
                          </div>
                          <div className="d-flex align-items-center mb-3">
                            <div className="col-2">
                              <label
                                htmlFor="performance_value"
                                className="form-label text-white">
                                Performance:
                              </label>
                            </div>
                            <div className="col-10">
                              <div className="input-group">
                                <span className="input-group-text bg-transparent text-white">
                                  <i className="bi bi-map"></i>
                                </span>
                                <select
                                  className="input-group form-select file-status text-white"
                                  id="performance_value"
                                  name="performance_value"
                                  value={formData.performance_value}
                                  onChange={handleChange}>
                                  <option value="1">Excellent</option>
                                  <option value="2">Good</option>
                                  <option value="3">Average</option>
                                  <option value="4">Low</option>
                                  <option value="5">Very Low</option>
                                  <option value="0">Unknown</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <hr />
                        {/* FILE NAME/PATH  */}
                        <div className="mb-3 px-2">
                          <div className="label mb-3">
                            <h6>FILE NAME/PATH</h6>
                          </div>
                          <div className="d-flex align-items-center mb-3">
                            <div className="col-2">
                              <label
                                htmlFor="common_name"
                                className="form-label text-white">
                                Common Name:
                              </label>
                            </div>
                            <div className="col-10">
                              <div className="input-group">
                                <span className="input-group-text bg-transparent text-white">
                                  <i className="bi bi bi-image"></i>
                                </span>
                                <input
                                  type="text"
                                  className="form-control asset-inputs"
                                  id="common_name"
                                  name="common_name"
                                  value={formData.common_name}
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="d-flex align-items-center mb-3">
                            <div className="col-2">
                              <label
                                htmlFor="filepath"
                                className="form-label text-white">
                                File Path:
                              </label>
                            </div>
                            <div className="col-10">
                              <div className="input-group">
                                <span className="input-group-text bg-transparent text-white">
                                  <i className="bi bi bi-image"></i>
                                </span>
                                <input
                                  type="text"
                                  className="form-control asset-inputs"
                                  id="filepath"
                                  name="filepath"
                                  value={formData.filepath}
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="d-flex align-items-center mb-3">
                            <div className="col-2">
                              <label
                                htmlFor="thumbnail"
                                className="form-label text-white">
                                File Thumbnail:
                              </label>
                            </div>
                            <div className="col-10">
                              <div className="input-group">
                                <input
                                  type="file"
                                  className="form-control asset-inputs"
                                  id="thumbnail"
                                  accept="image/*"
                                  onChange={handleFileChange}
                                />
                              </div>
                              <small className="form-text text-light">
                                File upload limit is 30 MB.
                              </small>
                            </div>
                          </div>
                          <div className="d-flex align-items-center mb-3">
                            <div className="col-2">
                              <label
                                htmlFor="creation_date"
                                className="form-label text-white">
                                Creation Date:
                              </label>
                            </div>
                            <div className="col-10">
                              <div className="input-group">
                                <DatePicker
                                  className="form-control asset-inputs"
                                  selected={
                                    formData.creation_date
                                      ? new Date(formData.creation_date)
                                      : null
                                  }
                                  onChange={(date) =>
                                    handleDateChange(date, "creation_date")
                                  }
                                  placeholderText="Select Date"
                                  dateFormat="MMM dd, yyyy"
                                  showMonthDropdown
                                  showYearDropdown
                                  scrollableYearDropdown
                                />
                              </div>
                            </div>
                          </div>
                          <div className="d-flex align-items-center mb-3">
                            <div className="col-2">
                              <label
                                htmlFor="start_date"
                                className="form-label text-white">
                                Start Date:
                              </label>
                            </div>
                            <div className="col-4">
                              <DatePicker
                                className="form-control asset-inputs"
                                selected={
                                  formData.asset_start_date
                                    ? new Date(formData.asset_start_date)
                                    : null
                                }
                                onChange={(date) =>
                                  handleDateChange(date, "asset_start_date")
                                }
                                placeholderText="Start Date"
                                dateFormat="MMM dd, yyyy"
                                showMonthDropdown
                                showYearDropdown
                                scrollableYearDropdown
                                maxDate={
                                  formData.asset_end_date
                                    ? new Date(formData.asset_end_date)
                                    : null
                                }
                              />
                            </div>
                            <div className="col-2">
                              <label
                                htmlFor="end_date"
                                className="form-label text-white">
                                End Date:
                              </label>
                            </div>
                            <div className="col-4">
                              <DatePicker
                                className="form-control asset-inputs"
                                selected={
                                  formData.asset_end_date
                                    ? new Date(formData.asset_end_date)
                                    : null
                                }
                                onChange={(date) =>
                                  handleDateChange(date, "asset_end_date")
                                }
                                placeholderText="End Date"
                                dateFormat="MMM dd, yyyy"
                                showMonthDropdown
                                showYearDropdown
                                scrollableYearDropdown
                                minDate={
                                  formData.asset_start_date
                                    ? new Date(formData.asset_start_date)
                                    : null
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <hr />
                        {/* OWNERSHIP */}
                        <div className="mb-3 px-2">
                          <div className="label mb-2">
                            <h6>OWNERSHIP</h6>
                          </div>
                          <div className="d-flex align-items-center">
                            <div className="col-2">
                              <label
                                htmlFor="file_owner"
                                className="form-label text-white">
                                File Owner:
                              </label>
                            </div>
                            <div className="col-10">
                              <div className="input-group">
                                <span className="input-group-text bg-transparent text-white">
                                  <i className="bi bi-person"></i>
                                </span>
                                <input
                                  type="text"
                                  className="form-control asset-inputs bg-transparent"
                                  id="file_owner"
                                  name="file_owner"
                                  value={formData.file_owner}
                                  onChange={handleChange}
                                  disabled={true}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <hr />
                        {/* CHANNEL */}
                        <div className="mb-3 px-2">
                          <div className="label mb-2">
                            <h6>CHANNEL</h6>
                          </div>
                          <div className="d-flex align-items-center">
                            <div className="col-2">
                              <label
                                htmlFor="channel"
                                className="form-label text-white">
                                Channel:
                              </label>
                            </div>
                            <div className="col-10">
                              <div className="input-group">
                                <span className="input-group-text bg-transparent text-white">
                                  <i className="bi-images"></i>
                                </span>
                                <select
                                  className="input-group form-select file-status text-white"
                                  id="channel"
                                  name="channel"
                                  required={true}
                                  value={formData.channel}
                                  onChange={handleChange}>
                                  <option value="" disabled selected>
                                    Please select a channel
                                  </option>
                                  <option value="print">Print</option>
                                  <option value="video_television">
                                    Video/Television
                                  </option>
                                  <option value="audio_radio">
                                    Audio/Radio
                                  </option>
                                  <option value="outofhome_billboards">
                                    Out of Home/Billboards
                                  </option>
                                  <option value="others">Others</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <hr />
                        {/* FILE META */}
                        <div className="mb-3 px-2">
                          <div className="label mb-2">
                            <h6>FILE META</h6>
                          </div>
                          <div className="d-flex justify-content-center mb-3 px-4">
                            <div className="col-4 mx-2">
                              <label
                                htmlFor="asset_type"
                                className="form-label text-white">
                                Asset Type:
                              </label>
                              <div className="input-group">
                                <span className="input-group-text bg-transparent text-white">
                                  <i className="bi-images"></i>
                                </span>
                                <input
                                  type="text"
                                  className="form-control asset-inputs bg-transparent"
                                  id="asset_type"
                                  name="asset_type"
                                  value={formData.asset_type}
                                  onChange={handleChange}
                                  disabled={true}
                                />
                              </div>
                            </div>
                            <div className="col-4 mx-2">
                              <label
                                htmlFor="extension"
                                className="form-label text-white">
                                File Extension:
                              </label>
                              <div className="input-group">
                                <span className="input-group-text bg-transparent text-white">
                                  <i className="bi bi-file-earmark"></i>
                                </span>
                                <input
                                  type="text"
                                  className="form-control asset-inputs bg-transparent"
                                  id="extension"
                                  name="extension"
                                  value={formData.extension}
                                  onChange={handleChange}
                                  disabled={true}
                                />
                              </div>
                            </div>
                            <div className="col-4 mx-2">
                              <label
                                htmlFor="color_range"
                                className="form-label text-white">
                                Color Range:
                              </label>
                              <div className="input-group">
                                <span className="input-group-text bg-transparent text-white">
                                  <i className="bi bi-palette"></i>
                                </span>
                                <select
                                  className="input-group form-select file-status text-white"
                                  id="color_range"
                                  name="color_range"
                                  value={formData.color_range}
                                  onChange={handleChange}>
                                  <option value="CMYK">CMYK</option>
                                  <option value="Hex">Hex</option>
                                  <option value="RGB">RGB</option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <div className="d-flex justify-content-center mb-3 px-4">
                            <div className="col-4 mx-2">
                              <label
                                htmlFor="size"
                                className="form-label text-white">
                                Size:
                              </label>
                              <div className="input-group">
                                <span className="input-group-text bg-transparent text-white">
                                  <i className="bi bi-aspect-ratio"></i>
                                </span>
                                <input
                                  type="text"
                                  className="form-control asset-inputs"
                                  id="size"
                                  name="size"
                                  value={formData.size}
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                            <div className="col-4 mx-2">
                              <label
                                htmlFor="width"
                                className="form-label text-white">
                                Width:
                              </label>
                              <div className="input-group">
                                <span className="input-group-text bg-transparent text-white">
                                  <i className="bi bi-aspect-ratio"></i>
                                </span>
                                <input
                                  type="text"
                                  className="form-control asset-inputs"
                                  id="width"
                                  name="width"
                                  value={formData.width}
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                            <div className="col-4 mx-2">
                              <label
                                htmlFor="height"
                                className="form-label text-white">
                                Height:
                              </label>
                              <div className="input-group">
                                <span className="input-group-text bg-transparent text-white">
                                  <i className="bi bi-aspect-ratio"></i>
                                </span>
                                <input
                                  type="text"
                                  className="form-control asset-inputs"
                                  id="height"
                                  name="height"
                                  value={formData.height}
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="d-flex justify-content-center mb-3 px-4">
                            <div className="col-4 mx-2">
                              <label
                                htmlFor="encoding"
                                className="form-label text-white">
                                Encoding:
                              </label>
                              <div className="input-group">
                                <span className="input-group-text bg-transparent text-white">
                                  <i className="bi-images"></i>
                                </span>
                                <select
                                  className="input-group form-select file-status text-white"
                                  id="encoding"
                                  name="encoding"
                                  value={formData.encoding}
                                  onChange={handleChange}>
                                  <option value="None">None</option>
                                  <option value="H.264">H.264, AAC</option>
                                  <option value="HD">HD (1-1-1)</option>
                                </select>
                              </div>
                            </div>
                            <div className="col-4 mx-2">
                              <label
                                htmlFor="audio"
                                className="form-label text-white">
                                Audio:
                              </label>
                              <div className="input-group">
                                <span className="input-group-text bg-transparent text-white">
                                  <i className="bi bi-file-earmark"></i>
                                </span>
                                <select
                                  className="input-group form-select file-status text-white"
                                  id="audio"
                                  name="audio"
                                  value={formData.audio}
                                  onChange={handleChange}>
                                  <option value="None">None</option>
                                  <option value="mono">Mono</option>
                                  <option value="stereo">Stereo</option>
                                </select>
                              </div>
                            </div>
                            <div className="col-4 mx-2">
                              <label
                                htmlFor="duration"
                                className="form-label text-white">
                                Duration:
                              </label>
                              <div className="input-group">
                                <span className="input-group-text bg-transparent text-white">
                                  <i className="bi-images"></i>
                                </span>
                                <input
                                  type="text"
                                  className="form-control asset-inputs"
                                  id="duration"
                                  name="duration"
                                  value={formData.duration}
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <hr />
                        <div className="row mb-3">
                          <div className="col-md-6 d-flex justify-content-end">
                            <button type="submit" className="btn btn-primary">
                              Submit
                            </button>
                          </div>
                          <div className="col-md-6">
                            <button
                              type="button"
                              className="btn btn-secondary"
                              onClick={() => handleClear("clear_file")}>
                              Clear
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default AssetUpload;
