export const categories = ["Organic", "Paid"];
export const status = ["Active", "Available", "Enabled", "Paused", "Removed", "Archived", "Published"];
export const platforms = [
  "Facebook",
  "Instagram",
  "Google_Ads",
  "Google_Analytics",
  "Linkedin",
  "Reddit",
  "TradeDesk",
  "Offline_Media"
];
export const disabledPlatforms = [];
export const types = ["Image", "Video", "Audio", "PDF", "Blog", "Document", "Webpage", "Link", "Text"];
export const performance = [5, 4, 3, 2, 1, 0];
// export const ETFs = ["NVDA"];
// export const Tiers = ["Tier1", "Tier2", "Tier3", "Focus Fund"];
export const sizes = [12, 6, 4, 3, 2];